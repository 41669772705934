import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  GridProps,
  Heading,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import type { Column } from 'react-table';
import { CopyButton } from '../../../components/CopyButton';
import { DataTable } from '../../../components/DataTable';
import { conditionalHeader } from '../../../components/DataTable/utils/dataTableUtils';
import { IfApplicableTable } from '../../../components/IfApplicableTable';
import { Card, MainCard } from '../../../components/Layout';
import { MarkdownContainer } from '../../../components/Output/MarkdownContainer';
import { InfoResult } from '../../../components/Result/InfoResult';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import {
  ContainerType,
  LclMeasurementType,
  LegCode,
  MainLegSource,
  QuoteNotificationType,
  ShippingMode,
  TransportMode,
} from '../../../types';
import type { Address, Charge, Port, Quote, Unit } from '../../../types';
import { IfApplicable } from '../../../types/IfApplicable';
import { HapagQuoteResponseDto } from '../../../types/api/HapagQuoteResponseDto';
import { API_ROUTES } from '../../../utils/apiRoutes';

interface QuotationDetailsProps {
  quotation: Quote;
  weightUnits: Unit[];
  quotationSource: MainLegSource;
  lclMeasurement?: LclMeasurementType;
  ifApplicables?: IfApplicable[];
  rateType?: string;
}

const descriptionForSource = {
  [MainLegSource.Undefined]: null,
  [MainLegSource.PricingEngine]: (
    <Badge colorScheme="blue" variant="solid">
      Pricing Manager
    </Badge>
  ),
  [MainLegSource.WebCargo]: (
    <Badge colorScheme="blue" variant="solid">
      WebCargo
    </Badge>
  ),
  [MainLegSource.CargoSphere]: (
    <Badge colorScheme="blue" variant="solid">
      CargoSphere
    </Badge>
  ),
  [MainLegSource.CargoOne]: (
    <Badge colorScheme="blue" variant="solid">
      cargo.one
    </Badge>
  ),
  [MainLegSource.HapagLloyd]: (
    <Badge colorScheme="blue" variant="solid">
      Hapag Lloyd
    </Badge>
  ),
};

const descriptionForShippingMode = {
  [ShippingMode.Undefined]: null,
  [ShippingMode.Error]: null,
  [ShippingMode.Port2Port]: (
    <Badge bgColor="grey.300" variant="solid">
      Port to Port
    </Badge>
  ),
  [ShippingMode.Door2Port]: (
    <Badge bgColor="grey.300" variant="solid">
      Door to Port
    </Badge>
  ),
  [ShippingMode.Port2Door]: (
    <Badge bgColor="grey.300" variant="solid">
      Port to Door
    </Badge>
  ),
  [ShippingMode.Door2Door]: (
    <Badge bgColor="grey.300" variant="solid">
      Door to Door
    </Badge>
  ),
};

export const QuotationDetails: FunctionComponent<QuotationDetailsProps> = ({
  quotation,
  weightUnits,
  quotationSource,
  lclMeasurement,
  ifApplicables,
}) => {
  const [canvasContext, setCanvasContext] =
    useState<CanvasRenderingContext2D | null>(
      document.createElement('canvas').getContext('2d'),
    );
  const theme = useTheme();
  const tdFontText =
    theme.components.Table.baseStyle.tbody.tr.td.fontSize +
    ' ' +
    theme.fonts.body;

  useEffect(() => {
    if (canvasContext && canvasContext.font !== tdFontText) {
      canvasContext.font = tdFontText;
      setCanvasContext(canvasContext);
    }
  }, [canvasContext, tdFontText]);

  const { countries } = useData();

  const quoteWeightUnit = weightUnits.find(
    (u) => u.code === quotation?.weightUnit,
  )?.name;

  const showRateInfo = useCallback(
    (charge: Charge) =>
      !charge.hideRateInfo && charge.charge > 0 && charge.currency,
    [],
  );

  const gridProps: GridProps = {
    templateColumns: 'repeat(2, 1fr)',
    columnGap: 4,
    fontWeight: 500,
  };
  const errorToast = useErrorToast();

  const [hapagQuote, setHapagQuote] = useState<HapagQuoteResponseDto | null>(
    null,
  );
  const { getApi } = useApi();
  const [hapagQuoteLoading, setHapagQuoteLoading] = useState<boolean>(false);
  const handleClick = useCallback(async () => {
    try {
      setHapagQuoteLoading(true);
      const result = await getApi(
        API_ROUTES.externalQuickQoute(quotation.quoteID),
      );

      if (result.ok) {
        setHapagQuote((await result.json()) as HapagQuoteResponseDto);
      }
      setHapagQuoteLoading(false);
    } catch (err) {
      setHapagQuote(null);
      setHapagQuoteLoading(false);
    }
  }, [getApi, quotation.quoteID]);
  const infoToast = useToast()

  useEffect(() => {
    if (!hapagQuote) return;
    if (!hapagQuote?.quotationNumber) {
      errorToast({
        title: 'Your qoute request has not been successfully to Hapag Lloyd! ',
        description: hapagQuote?.detail && (
          <Text>
            <br />
            {hapagQuote?.detail}
          </Text>
        ),
      });
    } else {
      infoToast({
        position: 'top',
        duration: 10_000,
        containerStyle: {
          maxWidth:'800px',
          minWidth:'600px'

        },
        isClosable: true,
        
        render: (props) => (
          <InfoResult 
            title=''
            description={
            <>
            <Text paddingBottom="6px">{ 'Great!' } <br /></Text>
            <Text>
                { 'Here is your Hapag-Lloyd quotation number.' } <br />
                { 'Proceed to CargoWise, where you can book directly.' } <br/>
                { 'Also you’ll get an email with the Quick Quote from Hapag-Lloyd.' } <br />
                <br />
                <strong> { 'Quotation Number: ' + hapagQuote.quotationNumber}</strong> <CopyButton marginLeft="16px" fontSize='10px' size='12px' copyValue={hapagQuote.quotationNumber ?? ''} /> <br />
                <br />
                {'Valid From: ' + hapagQuote.validFrom} <br />
                {'Valid to: ' + hapagQuote.validTo} <br />
                {'Expiry Date: ' + hapagQuote.expiryDate} <br />
                <br />
              </Text>
              <Button width="120px" colorScheme="blue" onClick={() => {infoToast.close(props.id)}}>Close</Button>
              </>
            }
          />
        ),
      });
    }
  }, [hapagQuote, errorToast, infoToast]);

  const renderAddress = (address: Address) => (
    <>
      <Text fontSize="xs">{address.street}</Text>
      <Text fontSize="xs">
        {address.zipCode} {address.city}
      </Text>
      <Text fontSize="xs">
        {countries.data?.find((c) => c.code === address?.countryCode)?.name}
      </Text>
    </>
  );

  const renderPort = (port: Port) => (
    <>
      <Text fontSize="xs">{port.name}</Text>
      {port.city && <Text fontSize="xs">{port.city}</Text>}
      <Text fontSize="xs">{port.country.name}</Text>
    </>
  );

  const renderRateInfo = useCallback(
    (charge: Charge) => {
      let factor: number;
      let unit: string;

      if (
        lclMeasurement === LclMeasurementType.Volume &&
        charge.legCode !== LegCode.OriginTransport &&
        charge.legCode !== LegCode.DestinationTransport
      ) {
        factor = charge.charge / quotation.totalVolumeCbm;
        unit = 'per CBM';
      } else if (lclMeasurement === LclMeasurementType.Weight) {
        factor = charge.charge / quotation?.totalWeightT ?? 1;
        unit = 'per 1000kg';
      } else {
        factor =
          charge.rateInfo ??
          charge.charge / (quotation?.chargeableWeightT * 1000 ?? 1);
        unit = 'per kg';
      }

      return (
        <Text variant="tableContent" title={factor.toString()}>
          {factor.toFixed(2)}
          &nbsp;{charge.currency.code}&nbsp;{unit}&nbsp;*
        </Text>
      );
    },
    [lclMeasurement, quotation],
  );

  const columns = useMemo<Column<Charge>[]>(
    () => [
      {
        Header: 'Charge Name',
        accessor: (charge) => charge.chargeCode.name,
      },
      {
        Header: 'Code',
        accessor: (charge) => charge.chargeCode.code,
      },
      {
        Header: 'Net Amount',
        accessor: (charge) =>
          charge.baseCharge > 0 ? (
            <>
              {charge.baseCharge.toFixed(2)}&nbsp;{charge.baseCurrency.code}
            </>
          ) : (
            '-'
          ),
      },
      ...conditionalHeader<Charge>(
        {
          Header: 'Rate Info',
          accessor: (charge) =>
            showRateInfo(charge) ? renderRateInfo(charge) : '-',
        },
        quotation.shipment.transportMode !== TransportMode.FCL,
      ),
      ...conditionalHeader<Charge>(
        {
          Header: 'Type',
          accessor: (charge) => charge.rateBreakdownType,
        },
        quotation.shipment.transportMode === TransportMode.FCL,
      ),
      {
        Header: 'Gross Amount',
        accessor: (charge) =>
          charge.charge > 0 && charge.currency ? (
            <>
              {charge.charge.toFixed(2)}&nbsp;{charge.currency.code}
            </>
          ) : (
            '-'
          ),
      },
      ...conditionalHeader<Charge>(
        {
          Header: 'Source',
          accessor: (charge) => {
            if (
              charge.chargeLineElementSource &&
              charge.chargeLineElementSource !== MainLegSource.Undefined
            ) {
              return descriptionForSource[charge.chargeLineElementSource];
            }
          },
        },
        quotation.shipment.transportMode === TransportMode.FCL,
      ),
    ],
    [quotation.shipment.transportMode, renderRateInfo, showRateInfo],
  );

  const renderChargeTable = useCallback(
    (title: string, legCodes: number[]) => {
      if (quotation == null) return;

      let data = quotation.charges.filter((charge) =>
        legCodes.includes(charge.legCode),
      );

      if (data.length === 0)
        data = [
          {
            chargeCode: { code: '-', name: '-' },
            baseCharge: 0,
            charge: 0,
            chargeCodeID: '0',
            legCode: legCodes[0],
            baseCurrency: {
              code: '-',
              currencyID: '',
              isConvertible: false,
              name: '-',
            },
            currency: {
              code: '-',
              currencyID: '',
              isConvertible: false,
              name: '-',
            },
            hideRateInfo: false,
            chargeLineElementSource: MainLegSource.Undefined,
          },
        ];

      const showsAnyRateInfo = data.some((charge: Charge) =>
        showRateInfo(charge),
      );

      return (
        <>
          {legCodes.includes(LegCode.Main) ? (
            <HStack justifyContent="space-between">
              <HStack spacing="4">
                <Heading fontSize="md">{title}</Heading>
                {descriptionForSource[quotationSource]}

                {quotation.rateType && (
                  <Badge colorScheme="blue" variant="solid">
                    {quotation.rateType}
                  </Badge>
                )}

                {quotation.carrierName && (
                  <Badge colorScheme="blue" variant="solid">
                    {quotation.carrierName}
                  </Badge>
                )}
              </HStack>
              {quotation.sourceSystemReference && (
                <Button
                  right="0"
                  paddingLeft="16px"
                  paddingRight="16px"
                  aria-label="Get your Hapag-Lloyd Quote"
                  backgroundColor="#FF6600"
                  _hover={{ bgColor: 'none' }} 
                  onClick={handleClick}
                  isLoading={hapagQuoteLoading}
                  loadingText='Get your Quote'
                  >
                    Get your Quote
                  </Button>
              )}
            </HStack>
          ) : (
            <Heading fontSize="md">{title}</Heading>
          )}
          <DataTable
            data={data}
            columns={columns}
            isLoading={false}
            fetchFailed={false}
            statusCode={0}
          />
          {showsAnyRateInfo && (
            <Text variant="tableContent">
              * Rate displayed is rounded to two decimals for visibility
              reasons, but calculation is made using all decimals
            </Text>
          )}
        </>
      );
    },
    [columns, quotation, quotationSource, showRateInfo, handleClick, hapagQuoteLoading],
  );

  const renderIfApplicableTable = useCallback(
    (countryId?: string) => {
      if (
        ifApplicables == null ||
        ifApplicables.length === 0 ||
        countryId == null
      ) {
        return;
      }

      const ifApplicableRates = ifApplicables?.filter(
        (rate) => rate.rateCountry.countryID === countryId,
      );

      if (ifApplicableRates.length === 0) {
        return;
      }

      return (
        <Accordion allowToggle={true} defaultIndex={0}>
          <AccordionItem as={Card} border="0px" paddingY="0" paddingX="8">
            <AccordionButton padding="8">
              <Heading
                color="grey.400"
                as="h2"
                fontSize="md"
                flex="1"
                textAlign="left"
                paddingBottom="0"
              >
                If Applicable
              </Heading>
              <AccordionIcon color="blue.500" />
            </AccordionButton>
            <AccordionPanel paddingTop="0" marginTop="-10">
              <IfApplicableTable
                ifApplicables={ifApplicableRates}
                renderType={quotation.shipment.transportMode}
                isLoading={false}
                canvasContext={canvasContext}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    },
    [canvasContext, ifApplicables, quotation.shipment.transportMode],
  );

  const getNotificationColor = (type: QuoteNotificationType) => {
    switch (type) {
      case QuoteNotificationType.Error:
        return 'red';
      case QuoteNotificationType.Warning:
        return 'orange';
      case QuoteNotificationType.Info:
      default:
        return 'white';
    }
  };

  const renderNotificationType = (type: QuoteNotificationType) => {
    const typeNotifications = quotation.notifications.filter(
      (notification) => notification.type === type,
    );
    if (typeNotifications.length === 0) {
      return null;
    }
    return (
      <Box marginBottom="8">
        <Heading as="h3" variant="h2" color={getNotificationColor(type)} mb="4">
          {QuoteNotificationType[type]}
        </Heading>
        <UnorderedList>
          {typeNotifications.map((notification) => (
            <ListItem fontSize="sm" key={notification.highlight}>
              {notification.message}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    );
  };

  const renderNotifications = () => {
    if (
      quotation.notifications?.length === 0 &&
      quotation.remarks?.length === 0
    ) {
      return null;
    }

    return (
      <Accordion allowToggle={true} defaultIndex={0}>
        <AccordionItem
          as={Card}
          border="1px solid"
          borderColor="orange !important"
          padding="0"
        >
          <AccordionButton padding="8" _focus={{ outline: "none" }}>
            <Heading as="h2" fontSize="lg" flex="1" textAlign="left">
              Notifications
            </Heading>
            <AccordionIcon color="blue.500" />
          </AccordionButton>
          <AccordionPanel
            fontWeight="bold"
            paddingX="8"
            paddingBottom="8"
            paddingTop="0"
          >
            {quotation.notifications?.length > 0 && (
              <>
                {renderNotificationType(QuoteNotificationType.Error)}
                {renderNotificationType(QuoteNotificationType.Warning)}
                {renderNotificationType(QuoteNotificationType.Info)}
              </>
            )}
            {quotation.remarks && quotation.remarks?.length > 0 && (
              <Box>
                <Heading
                  as="h2"
                  fontSize="lg"
                  flex="1"
                  textAlign="left"
                  marginBottom="4"
                  color="orange"
                >
                  Remarks
                </Heading>
                <MarkdownContainer markdown={quotation.remarks} />
              </Box>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <>
      {renderNotifications()}
      <MainCard spacing="7" heading="Quotation Detail">
        <Grid
          templateColumns={{ base: 'repeat(1)', md: 'repeat(3, 1fr)' }}
          gap={`1 0`}
        >
          <GridItem>
            <Grid {...gridProps}>
              <GridItem fontSize="xs" color="grey.400">
                Transport Type
              </GridItem>
              <GridItem fontSize="xs">
                <HStack>
                  <Text fontSize="xs">
                    {TransportMode[quotation.shipment.transportMode]}
                  </Text>
                  {descriptionForShippingMode[quotation.shipment.shippingMode]}
                </HStack>
              </GridItem>
              <GridItem fontSize="xs" color="grey.400">
                Currency
              </GridItem>
              <GridItem fontSize="xs">{quotation.currency.code}</GridItem>
              {quotation.shipment.transportMode === TransportMode.FCL ? (
                <>
                  <GridItem fontSize="xs" color="grey.400">
                    Cargo weight
                  </GridItem>
                  <GridItem fontSize="xs">
                    {quotation.totalWeight.toFixed(2)}&nbsp;{quoteWeightUnit}
                  </GridItem>
                  <GridItem fontSize="xs" color="grey.400">
                    Container Type
                  </GridItem>
                  <GridItem fontSize="xs">
                    {ContainerType[quotation.containerType]}
                  </GridItem>
                  <GridItem fontSize="xs" color="grey.400">
                    Container Quantity
                  </GridItem>
                  <GridItem fontSize="xs">
                    {quotation.containerQuantity}
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem fontSize="xs" color="grey.400">
                    Gross weight
                  </GridItem>
                  <GridItem fontSize="xs">
                    {quotation.totalWeight.toFixed(2)}&nbsp;{quoteWeightUnit}
                  </GridItem>
                  <GridItem fontSize="xs" color="grey.400">
                    Volume
                  </GridItem>
                  <GridItem fontSize="xs">
                    {quotation.totalVolumeCbm.toFixed(2)}&nbsp;m<sup>3</sup>
                  </GridItem>
                  <GridItem fontSize="xs" color="grey.400">
                    {quotation.shipment.transportMode === TransportMode.LCL
                      ? 'Chargeable'
                      : 'Chargeable weight'}
                  </GridItem>
                  <GridItem fontSize="xs">
                    {quotation.shipment.transportMode === TransportMode.LCL ? (
                      lclMeasurement === LclMeasurementType.Volume ? (
                        <>
                          {quotation.totalVolumeCbm.toFixed(2)}&nbsp;m
                          <sup>3</sup>
                        </>
                      ) : (
                        <>
                          {quotation.totalWeight.toFixed(2)}&nbsp;
                          {quoteWeightUnit}
                        </>
                      )
                    ) : (
                      <>
                        {quotation.chargeableWeight.toFixed(2)}&nbsp;
                        {quoteWeightUnit ?? 'kg'}
                      </>
                    )}
                  </GridItem>
                </>
              )}
            </Grid>
          </GridItem>
          <GridItem>
            <Grid {...gridProps}>
              <GridItem
                fontSize="xs"
                color="grey.400"
                alignSelf="start"
                justifySelf={{ base: 'start', md: 'end' }}
              >
                Origin
              </GridItem>
              <GridItem>
                {quotation.shipment.originAddress
                  ? renderAddress(quotation.shipment.originAddress)
                  : '-'}
              </GridItem>
              <GridItem
                fontSize="xs"
                color="grey.400"
                alignSelf="start"
                justifySelf={{ base: 'start', md: 'end' }}
              >
                POL
              </GridItem>
              <GridItem>
                {quotation.shipment.originPort
                  ? renderPort(quotation.shipment.originPort)
                  : '-'}
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Grid {...gridProps}>
              <GridItem
                fontSize="xs"
                color="grey.400"
                alignSelf="start"
                justifySelf={{ base: 'start', md: 'end' }}
              >
                Destination
              </GridItem>
              <GridItem>
                {quotation.shipment.destinationAddress
                  ? renderAddress(quotation.shipment.destinationAddress)
                  : '-'}
              </GridItem>
              <GridItem
                fontSize="xs"
                color="grey.400"
                alignSelf="start"
                justifySelf={{ base: 'start', md: 'end' }}
              >
                POD
              </GridItem>
              <GridItem>
                {quotation.shipment.destinationPort
                  ? renderPort(quotation.shipment.destinationPort)
                  : '-'}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>

        <Divider borderColor="grey.400" />

        {renderChargeTable('Origin Charges', [
          LegCode.OriginPort,
          LegCode.OriginTransport,
        ])}
      </MainCard>

      {renderIfApplicableTable(
        quotation.shipment.originPort?.country.countryID,
      )}
      <Card spacing="7" flex="1">
        {renderChargeTable('International Freight', [LegCode.Main])}
      </Card>
      <Card spacing="7" flex="1">
        {renderChargeTable('Destination Charges', [
          LegCode.DestinationPort,
          LegCode.DestinationTransport,
        ])}
      </Card>
      {renderIfApplicableTable(
        quotation.shipment.destinationPort?.country.countryID,
      )}
    </>
  );
};
