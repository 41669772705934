export interface Address {
  countryCode: string;
  zipCode: string;
  city: string;
  street: string;
  name: string;
  locationString: string;
}

export const isAddress = (address: any) =>
  address &&
  'countryCode' in address &&
  'zipCode' in address &&
  'city' in address &&
  'street' in address &&
  'name' in address &&
  'locationString' in address;
