import { Control } from 'react-hook-form';
import type { Column } from 'react-table';
import { CalculatorTariff } from '../../../../types/CalculatorTariff';
import { MethodTariff } from '../../../../types/MethodTariff';
import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';
import { TariffInputCell } from '../components/TariffInputCell';
import { TariffWeightInputCell } from '../components/TariffWeightInputCell';
import { TariffZoneInputCell } from '../components/TariffZoneInputCell';

export const getTariffEditColumns = (
  tariffWeightBrackets: BracketWeightDto[],
  errors: any,
  register: any,
  control: Control<any>,
  method: MethodTariff,
  calculator: CalculatorTariff,
  country: string,
  setValue?: (zoneKey: string, newValue: string) => void,
): Column[] => {
  // TODO the first zone cell should be prefilled with `0` and readonly.
  const tableMethodHeader =
    method === MethodTariff.ZipCodes ? 'ZIP Code' : 'Distance Zone (KM)';
  const generatedColumns: Column[] = [
    {
      Header: tableMethodHeader,
      accessor: (rate: Record<string, any>, cellIndex: number) => (
        <TariffZoneInputCell
          accessor={`zone-${cellIndex}`}
          errors={errors}
          register={register}
          control={control}
          country={country}
          method={method}
          defaultValue={rate.zone}
          setValue={setValue}
          zoneKey={cellIndex}
        />
      ),
      width: 200,
    },
  ];
  tariffWeightBrackets.forEach((cell, cellIndex: number) => {
    if (cell.isMinValue && calculator !== CalculatorTariff.FLT) {
      generatedColumns.push({
        Header: 'Minimum',
        accessor: (rate: Record<string, any>, index: number) => (
          <TariffInputCell
            accessor={`${cellIndex}-${index}`}
            control={control}
            isRequired={false}
            defaultValue={rate[cell.bracketWeightID]}
          />
        ),
      });
    } else {
      generatedColumns.push({
        Header: () => (
          <TariffWeightInputCell
            accessor={`header-${cellIndex}`}
            control={control}
            defaultValue={cell.value || undefined}
            label="Below "
          />
        ),
        id: `column-${cellIndex}`,
        accessor: (rate: Record<string, any>, index: number) => (
          <TariffInputCell
            accessor={`${cellIndex}-${index}`}
            control={control}
            isRequired={false}
            defaultValue={rate[cell.bracketWeightID]}
          />
        ),
      });
    }
  });
  return generatedColumns;
};
