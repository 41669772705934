import create from 'zustand';
import type { QuotationFormData, Quote } from '../types';
import { MainLegSource, LclMeasurementType } from '../types';
import { IfApplicable } from '../types/IfApplicable';

interface PageStateStore {
  quotationPage: {
    showQuotation?: boolean;
    quotation?: Quote;
    quotationSource?: MainLegSource;
    lclMeasurement?: LclMeasurementType;
    ifApplicables?: IfApplicable[];
    rateType?: string;
  };
  originSearch: string;
  destinationSearch: string;
  formData?: QuotationFormData;
  initQuotationPage: () => void;
  showQuotationDetails: (param?: boolean) => void;
  setQuotation: (
    quotation: Quote,
    quotationSource: MainLegSource,
    lclMeasurement: LclMeasurementType,
    ifApplicable: IfApplicable[],
    rateType: string,
  ) => void;
  setFormData: (formData?: QuotationFormData) => void;
  setOriginSearch: (originSearch: string) => void;
  setDestinationSearch: (destinationSearch: string) => void;
}

export const useQuotationState = create<PageStateStore>((set) => ({
  quotationPage: {
    showQuotation: false,
  },
  originSearch: '',
  destinationSearch: '',
  initQuotationPage: () =>
    set(() => ({
      quotationPage: {
        showQuotation: false,
        quotation: undefined,
        quotationSource: MainLegSource.Undefined,
        lclMeasurement: LclMeasurementType.Undefined,
        ifApplicables: undefined,
        rateType: undefined,
      },
      formData: undefined,
      originSearch: '',
      destinationSearch: '',
    })),
  showQuotationDetails: (param?: boolean) =>
    set(() => ({
      quotationPage: {
        showQuotation: param ?? true,
      },
    })),
  setQuotation: (
    quotation: Quote,
    quotationSource: MainLegSource,
    lclMeasurement: LclMeasurementType,
    ifApplicables: IfApplicable[],
    rateType: string,
  ) =>
    set(() => ({
      quotationPage: {
        showQuotation: true,
        quotation,
        quotationSource,
        lclMeasurement,
        ifApplicables,
        rateType,
      },
    })),
  setFormData: (formData?: QuotationFormData) =>
    set(() => ({
      formData,
    })),
  setOriginSearch: (originSearch: string) =>
    set(() => ({
      originSearch,
    })),
  setDestinationSearch: (destinationSearch: string) =>
    set(() => ({
      destinationSearch,
    })),
}));
