import { useDisclosure, Text } from '@chakra-ui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CancelModal } from '../../../../components/Modals/CancelModal';
import { DeactivateModal } from '../../../../components/Modals/DeactivateModal';
import { DuplicateModal } from '../../../../components/Modals/DuplicateModal';
import { SaveChangesModal } from '../../../../components/Modals/SaveChangesModal';
import { NotificationBox } from '../../../../components/Output/NotificationBox';
import { useDecoratedApi } from '../../../../hooks/api/useDecoratedApi';
import { useErrorToast } from '../../../../hooks/useErrorToast';
import { useSuccessToast } from '../../../../hooks/useSuccessToast';
import { useTariffCreateStore } from '../../../../hooks/useTariffCreateStore';
import { HttpMethod } from '../../../../providers/ApiProvider';
import { useData } from '../../../../providers/DataProvider';
import { QuoteNotification, QuoteNotificationType } from '../../../../types';
import { MethodTariff } from '../../../../types/MethodTariff';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { CreateTariffMetaValues } from '../../../../types/forms/CreateTariffMetaValues';
import { API_ROUTES } from '../../../../utils/apiRoutes';
import { findPortsByIdList } from '../../../../utils/model/findPortByID';
import { getDirectionType } from '../../../../utils/model/getDirectionType';
import { getMetaFromRate } from '../../../../utils/model/getMetaFromRate';
import { transformTariffTruckingDtoToModel } from '../../../../utils/model/transformTariffTruckingDtoToModel';
import { getRateFormValuesSorted } from '../../TariffCreateRatesPage/utils/getRateFormValuesSorted';
import { useDynamicTariffTableForm } from '../hooks/useDynamicTariffTableForm';
import { useTariffDeactivate } from '../hooks/useTariffDeactivate';
import { useTariffDuplicate } from '../hooks/useTariffDuplicate';
import { useTariffEditOptions } from '../hooks/useTariffEditOptions';
import { getInitialMetaFormValues } from '../utils/getInitialMetaFormValues';
import { TariffHeading } from './TariffHeading';
import { TariffMetaEditForm } from './TariffMetaEditForm';
import { TariffMetaInformation } from './TariffMetaInformation';
import { TariffTable } from './TariffTable';

export const TariffPageContent: FunctionComponent<{
  rate: TariffTruckingDto;
  initialEdit: boolean;
  onSuccess: () => void;
}> = ({ rate, initialEdit = false, onSuccess }) => {
  const { currencies } = useData();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const cancelModal = useDisclosure();
  const saveModal = useDisclosure();
  const deactivateModal = useDisclosure();
  const duplicateModal = useDisclosure();

  const [notificationsList, setNotificationsList] =
    useState<QuoteNotification[]>();

  const { rateForm } = useTariffCreateStore();
  const { deleteLoading, deleteResource } = useTariffDeactivate();
  const { duplicateResource, duplicateLoading } = useTariffDuplicate();

  const [rateMeta, setRateMeta] = useState<CreateTariffMetaValues>();
  const [isEdit, setIsEdit] = useState<boolean>(initialEdit);

  const tariffEditOptions = useTariffEditOptions(rate.country.countryID);

  const initialFormValues = getInitialMetaFormValues(rate);

  const metaEditForm = useForm<CreateTariffMetaValues>({
    defaultValues: { ...initialFormValues },
  });

  const { sendRequest, loading: updateLoading } = useDecoratedApi(
    'Failed to update',
    HttpMethod.PUT,
  );

  const watchedCalculator = metaEditForm.watch('calculator');
  const watchedMethod = metaEditForm.watch('method');

  const {
    onAddRow,
    onDeleteRow,
    onDeleteColumn,
    onAddColumn,
    getValues,
    handleSubmit,
    reset,
    rowCount,
    columnCount,
    rows,
    columns,
  } = useDynamicTariffTableForm(
    watchedMethod,
    watchedCalculator,
    rate.country.code,
    rate,
    rateForm,
    isEdit,
  );

  useEffect(() => {
    if (rate) {
      setRateMeta(getMetaFromRate(rate));
    }
  }, [rate]);

  const postInvalidFormNotification = () => {
    errorToast({
      title: 'Form Errors',
      description: 'Please check the form for any errors',
    });
  };

  const onHandleSubmit = () => {
    handleSubmit(
      () => {
        setNotificationsList([]);
        metaEditForm.handleSubmit(
          (metaFormItems) => {
            const getDirections = () =>
              metaFormItems.directions.map((direction) =>
                getDirectionType(direction),
              );

            if (rate) {
              const { zones, headers, cells } = getRateFormValuesSorted(
                getValues(),
                rowCount,
                columnCount,
              );
              const requestBody = transformTariffTruckingDtoToModel(
                metaFormItems,
                rate,
                zones,
                headers,
                cells,
                currencies,
                metaFormItems.transportType || 0,
                getDirections(),
                findPortsByIdList(
                  tariffEditOptions.ports,
                  metaEditForm.getValues('assignedPorts'),
                ),
                findPortsByIdList(
                  tariffEditOptions.airports,
                  metaEditForm.getValues('assignedAirports'),
                ),
              );

              sendRequest(
                API_ROUTES.tariffsTruckingSingle(rate.tariffID),
                requestBody,
              ).then((response) => {
                if (response) {
                  successToast({
                    title: 'Succesfully updated the tariff',
                  });
                  saveModal.onClose();
                  setIsEdit(false);
                  setNotificationsList([]);
                  onSuccess();
                }
              });
            }
          },
          () => {
            postInvalidFormNotification();
            saveModal.onClose();
          },
        )();
      },
      (rateFormErrors) => {
        postInvalidFormNotification();
        const zoneError = Object.keys(rateFormErrors).find((rateFieldKey) =>
          rateFieldKey.includes('zone-'),
        );
        if (zoneError && watchedMethod === MethodTariff.ZipCodes) {
          setNotificationsList([
            {
              type: QuoteNotificationType.Error,
              message: (
                <Text>
                  Some of the ZIP codes you entered are not in the correct
                  format. Please ensure all ZIP codes follow the correct syntax
                  for {rate.country?.name}.
                  {!!rate.country?.zipFormats?.length ? (
                    <>
                      <br /> For reference, a valid ZIP code example is:
                    </>
                  ) : (
                    ''
                  )}{' '}
                  <b>
                    {rate.country?.zipFormats
                      ?.map((zipExample) => `${zipExample}`)
                      .join(', ') || ''}
                  </b>
                  <br />
                  <br /> Kindly review your entries and try again.
                </Text>
              ),
              highlight:
                rate.country?.zipFormats
                  ?.map((zipExample) => `${zipExample}`)
                  .join(', ') || '',
            },
          ]);
        }
        saveModal.onClose();
      },
    )();
  };

  const handleSaveClick = () => {
    saveModal.onOpen();
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    reset();
    cancelModal.onClose();
  };

  return (
    <>
      <TariffHeading
        title={rate.name}
        isEdit={isEdit}
        onCancel={cancelModal.onOpen}
        onSave={handleSaveClick}
        onDeactivate={deactivateModal.onOpen}
        onDuplicate={duplicateModal.onOpen}
        onEdit={() => setIsEdit(true)}
        countryId={rate.country.countryID}
      />
      {!!notificationsList?.length && (
        <NotificationBox
          type={QuoteNotificationType.Error}
          typeNotifications={notificationsList}
        />
      )}
      {!isEdit && (
        <TariffMetaInformation
          metaInformation={rateMeta}
          country={rate.country}
          ports={rate.assignedPorts}
        />
      )}
      {isEdit && tariffEditOptions.airports && (
        <TariffMetaEditForm
          airports={tariffEditOptions.formattedAirports}
          airportsList={tariffEditOptions.airports}
          ports={tariffEditOptions.formattedPorts}
          portsList={tariffEditOptions.ports}
          currencies={tariffEditOptions.formattedCurrencies}
          transportType={rate.transportMode}
          selectedCountry={tariffEditOptions.selectedCountry}
          formInstance={metaEditForm}
          initialFormValues={initialFormValues}
          lockRatio={true}
          lockCalculatedMethods={true}
        />
      )}
      <form>
        <TariffTable
          isLoading={false}
          fetchFailed={false}
          statusCode={0}
          isEdit={isEdit}
          rows={rows}
          columns={columns}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          onAddColumn={onAddColumn}
          onDeleteColumn={onDeleteColumn}
        />
      </form>
      <SaveChangesModal
        isOpen={saveModal.isOpen}
        isLoading={updateLoading}
        onAction={onHandleSubmit}
        onCancel={saveModal.onClose}
      />
      <CancelModal
        isOpen={cancelModal.isOpen}
        onCancel={cancelModal.onClose}
        onAction={handleCancelEdit}
      />
      <DeactivateModal
        isOpen={deactivateModal.isOpen}
        onCancel={deactivateModal.onClose}
        onAction={() => deleteResource(rate)}
        isLoading={deleteLoading}
      />
      <DuplicateModal
        isOpen={duplicateModal.isOpen}
        onCancel={duplicateModal.onClose}
        onAction={() => duplicateResource(rate)}
        isLoading={duplicateLoading}
      />
    </>
  );
};
