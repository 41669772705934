import { DeleteIcon } from '@chakra-ui/icons';
import {
  Divider,
  GridItem,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  SimpleGridProps,
  Stack,
} from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { As, Button, Grid } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import {
  BoatIcon,
  LocalIcon,
  PlaneIcon,
  PlusIcon,
} from '../../../components/Icons';
import { SelectFormInput } from '../../../components/Input';
import { RadioFormInput } from '../../../components/Input/RadioFormInput';
import { SearchInput } from '../../../components/Input/SearchInput';
import { valueOrDefault } from '../../../components/LabelUtils';
import { Card, MainCard } from '../../../components/Layout';
import { ListHeading } from '../../../components/Layout/ListHeading';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useQuotationState } from '../../../hooks/useQuotationState';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import {
  Address,
  Package,
  QuotationCargoFormData,
  Place,
  Port,
  QuotationFormData,
  QuoteRequest,
  QuoteResponse,
  Unit,
  ContainerType,
  WeightUnit,
  isAddress,
  isPort,
  FreightType,
  MainLegSource,
  PlaceType,
  TransportMode,
  Status,
  getPlaceTypeFromTransportMode,
  getPortTypeFromTransportMode,
  LengthUnit,
} from '../../../types';
import { AlgoliaPlace } from '../../../types/AlgoliaPlace';
import { PlaceResponse } from '../../../types/PlaceResponse';
import {
  convertLengthUnit,
  convertWeightUnit,
} from '../../../utils/UnitConversion';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { InfoItem } from '../../AccountPage/InfoItem';
import { CargoInput } from './CargoInput';
import { ContainerInput } from './ContainerInput';
import { PlaceDetails } from './PlaceDetails';

const getDefaultPackageItem = () =>
  ({
    quantity: 1,
    weight: '',
    weightUnit: 2,
    width: '',
    height: '',
    length: '',
    lengthsUnit: 52,
  } as QuotationCargoFormData);

interface QuotationFormProps {
  lengthUnits: Unit[];
  weightUnits: Unit[];
  containerTypes: Unit[];
}

export const QuotationForm: FunctionComponent<QuotationFormProps> = ({
  lengthUnits,
  weightUnits,
  containerTypes,
}) => {
  const { getApi } = useApi();
  const onMobile = useBreakpointValue({ base: true, md: false });
  const onZoom = useBreakpointValue({ base: true, xl: false });
  const colSpanValue = useBreakpointValue({ base: 1, md: 2 });
  const { currencies } = useData();
  const { postApi } = useApi();
  const errorToast = useErrorToast();
  const { formData, setFormData, setQuotation } = useQuotationState();
  const [algoliaPlaces, setAlgoliaPlaces] = useState<AlgoliaPlace[]>([]);

  const simpleGridProps: SimpleGridProps = {
    columns: { base: 1, md: 2 },
    spacing: { base: '8', xl: '12' },
  };

  const defaultValues = useMemo(
    () => ({
      originValue: formData?.originValue ?? '',
      origin: formData?.origin,
      destinationValue: formData?.destinationValue ?? '',
      destination: formData?.destination,
      cargo: formData?.cargo ?? [getDefaultPackageItem()],
      containers: formData?.containers ?? {
        quantity: 1,
        weightUnit: WeightUnit.kg,
        containerType: ContainerType['20GP'],
        weight: '',
      },
      currency:
        formData?.currency ??
        (!currencies.isLoading && currencies.data
          ? currencies.data.find((currency) => currency.code === 'EUR')
              ?.currencyID
          : ''),
      transport: formData?.transport ?? TransportMode.Air,
    }),
    [currencies, formData],
  );

  const [debouncedOriginQuery] = useDebounce(formData?.originValue, 500);
  const [debouncedDestinationQuery] = useDebounce(
    formData?.destinationValue,
    500,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    reset,
    watch,
  } = useForm<QuotationFormData>({
    defaultValues,
  });

  const { cargo, transport, origin, destination, currency, containers } =
    watch();

  const addPackage = useCallback(() => {
    setValue('cargo', [...cargo, getDefaultPackageItem()]);
  }, [cargo, setValue]);

  const removePackage = useCallback(
    (index: number) => {
      const list = [...cargo];
      list.splice(index, 1);
      setValue('cargo', list);
    },
    [cargo, setValue],
  );

  const placeToString = (item: Place | null) => item?.placeName ?? '';

  const displayPlaceResult = (
    item: Place,
    index: number,
    highlightedIndex: number | null,
    icon: As<any> | undefined,
    getItemProps: CallableFunction,
  ) => (
    <ListItem
      p="2"
      fontSize="xs"
      backgroundColor={highlightedIndex === index ? 'grey.500' : 'grey.800'}
      {...getItemProps({
        index,
        item,
      })}
    >
      <ListIcon as={icon} />
      {placeToString(item)}
    </ListItem>
  );

  const displayPlaceResults = (
    items: Place[],
    highlightedIndex: number | null,
    getItemProps: CallableFunction,
    getMenuProps: CallableFunction,
  ) => {
    const localPlaceType = getPlaceTypeFromTransportMode(transport);

    return (
      <List {...getMenuProps()}>
        {items
          .filter((item) => item.placeType === localPlaceType)
          .slice(0, 5)
          .map((item, index) => (
            <React.Fragment key={placeToString(item)}>
              {index === 0 ? (
                <ListHeading>
                  {localPlaceType === PlaceType.Airport ? 'Airports' : 'Ports'}
                </ListHeading>
              ) : null}
              {displayPlaceResult(
                item,
                index,
                highlightedIndex,
                localPlaceType === PlaceType.Airport ? PlaneIcon : BoatIcon,
                getItemProps,
              )}
            </React.Fragment>
          ))}
        {items
          .filter((item) => item.placeType === PlaceType.Address)
          .map((item, index) => (
            <React.Fragment key={placeToString(item)}>
              {index === 0 ? <ListHeading>Addresses</ListHeading> : null}
              {displayPlaceResult(
                item,
                (index + 1) * 10,
                highlightedIndex,
                LocalIcon,
                getItemProps,
              )}
            </React.Fragment>
          ))}
      </List>
    );
  };

  const { isLoading: isPortsLoading, data: ports } = useQuery<
    Port[] | undefined
  >(
    ['ports', transport],
    async () => {
      const portType = getPortTypeFromTransportMode(transport);
      const result = await getApi(
        portType ? `ports?portType=${portType}` : 'ports',
      );
      if (result.ok) {
        return (await result.json()) as Port[];
      }
    },
    { refetchOnWindowFocus: false },
  );

  const selectWeightUnit = (cargos: QuotationCargoFormData[]): WeightUnit => {
    if (cargos.length === 0) return WeightUnit.kg;
    return cargos[0].weightUnit;
  };
  const selectWeightUnitString = (cargos: QuotationCargoFormData[]): string =>
    WeightUnit[selectWeightUnit(cargos)];

  const calcVolume = (cargoFormData: QuotationCargoFormData) => {
    if (
      cargoFormData.width === '' ||
      cargoFormData.length === '' ||
      cargoFormData.height === ''
    )
      return 0;
    const desiredUnit = LengthUnit.m;
    return (
      convertLengthUnit(
        +cargoFormData.width,
        cargoFormData.lengthsUnit,
        desiredUnit,
      ) *
      convertLengthUnit(
        +cargoFormData.length,
        cargoFormData.lengthsUnit,
        desiredUnit,
      ) *
      convertLengthUnit(
        +cargoFormData.height,
        cargoFormData.lengthsUnit,
        desiredUnit,
      )
    );
  };

  const volumeReduce = (cargos: QuotationCargoFormData[]) =>
    cargos.reduce(
      (acc: number, selectedCargo: QuotationCargoFormData) =>
        acc + +selectedCargo.quantity * calcVolume(selectedCargo),
      0,
    );

  const weightReduce = (cargos: QuotationCargoFormData[]) => {
    const desiredUnit = selectWeightUnit(cargo);
    return cargos.reduce(
      (acc: number, selectedCargo: QuotationCargoFormData) =>
        acc +
        +selectedCargo.quantity *
          convertWeightUnit(
            +selectedCargo.weight,
            selectedCargo.weightUnit,
            desiredUnit,
          ),
      0,
    );
  };

  const comparePortToAlgolia = (
    transportMode: TransportMode,
    port: Port,
    algoliaPlace?: AlgoliaPlace,
  ) => {
    const isUnloCodeMatching =
      `${port.country?.code?.toLowerCase()}${port.unloCode.toLowerCase()}` ===
      algoliaPlace?.locode?.toLowerCase();

    if (transportMode === TransportMode.Air) {
      const isIataCodeExistingAndMatching =
        algoliaPlace?.iata &&
        port.iataCode &&
        port.iataCode.toLowerCase() === algoliaPlace?.iata.toLowerCase();

      return isIataCodeExistingAndMatching || isUnloCodeMatching;
    }

    return isUnloCodeMatching;
  };

  const getPlaces = useCallback(
    async (query: string) => {
      if (query !== '') {
        try {
          const result = await getApi(
            `places?input=${query}&transportMode=${transport}`,
          );

          if (result.ok) {
            const places = (await result.json()) as PlaceResponse;
            const addressPlaces = places.googlePlaces.map((address) => ({
              ...address,
              placeType: PlaceType.Address,
            }));

            setAlgoliaPlaces(places.algoliaLocations);

            // This filtering is just to show the user only the ports we really know. Might not be necessary anymore when the dataquality of algolia improves
            const portPlaces = places.algoliaLocations
              .filter(
                (location) =>
                  ports?.find((port) =>
                    comparePortToAlgolia(transport, port, location),
                  ) != null,
              )
              .map((port) => ({
                placeId: port.objectID as string,
                placeName: [port.name, port.country_Name].join(', '),
                placeType: getPlaceTypeFromTransportMode(transport),
              }));

            return [...portPlaces, ...addressPlaces];
          }
        } catch {
          errorToast({
            title:
              'Something went wrong while getting locations. Please try again',
          });
          return [];
        }
      }
    },
    [errorToast, getApi, ports, transport],
  );

  const { isLoading: isOriginLoading, data: originItems } = useQuery<
    Place[] | undefined
  >(
    ['places', { debouncedOriginQuery }],
    async () => getPlaces(debouncedOriginQuery ?? ''),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: isDestinationLoading, data: destinationItems } = useQuery<
    Place[] | undefined
  >(
    ['places', { debouncedDestinationQuery }],
    async () => getPlaces(debouncedDestinationQuery ?? ''),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const getPlaceDetails = useCallback(
    async (place?: Place) => {
      if (place && place.placeType === PlaceType.Address) {
        const result = await getApi(`places/${place.placeId}`);
        if (result.ok) {
          return (await result.json()) as Address;
        }
      } else if (place && ports && algoliaPlaces) {
        const algoliaPlace = algoliaPlaces.find(
          (aPlace) => aPlace.objectID === place.placeId,
        );

        return ports.find((port) =>
          comparePortToAlgolia(transport, port, algoliaPlace),
        );
      }
    },
    [algoliaPlaces, getApi, ports, transport],
  );

  const onPlaceSelection = useCallback(
    async (place: Place | null, isOrigin: boolean) => {
      if (place) {
        const details = (await getPlaceDetails(place)) || '';

        if (details) {
          setValue(
            isOrigin ? 'origin' : 'destination',
            details as Address | Port,
          );
          setValue(
            isOrigin ? 'originValue' : 'destinationValue',
            placeToString(place),
          );
        }
      } else {
        setValue(isOrigin ? 'origin' : 'destination', undefined);
      }
    },
    [getPlaceDetails, setValue],
  );

  const onSubmit = useCallback(
    async (submitData: QuotationFormData) => {
      setFormData(submitData);

      const quoteRequest: QuoteRequest = {
        transportMode: submitData.transport,
        quotingSource: MainLegSource.Undefined,
        dangerousGoods: false,
        lengthsUnit: lengthUnits.find(
          (u) => u.code === submitData.cargo[0].lengthsUnit,
        )?.code,
        weightUnit: weightUnits.find(
          (u) => u.code === submitData.cargo[0].weightUnit,
        )?.code,
        outputCurrency: currencies?.data?.find(
          (c) => c.currencyID === submitData.currency,
        )!,
        packages: [] as Package[],
        container: undefined,
      };

      if (isPort(submitData.origin)) {
        quoteRequest.originPort = submitData.origin as Port;
      } else if (isAddress(submitData.origin)) {
        quoteRequest.originAddress = submitData.origin as Address;
      }
      if (isPort(submitData.destination)) {
        quoteRequest.destinationPort = submitData.destination as Port;
      } else if (isAddress(submitData.destination)) {
        quoteRequest.destinationAddress = submitData.destination as Address;
      }

      if (submitData.transport !== TransportMode.FCL) {
        submitData.cargo.forEach((packageItem: QuotationCargoFormData) => {
          const { quantity, weight, width, height, length, ...rest } =
            packageItem;
          for (let i = 0; i < quantity; i++) {
            quoteRequest.packages.push({
              ...rest,
              weight: parseFloat(weight as string),
              width: parseFloat(width as string),
              height: parseFloat(height as string),
              length: parseFloat(length as string),
              freightID: uuidv4(),
              freightType: FreightType.Package,
            });
          }
        });
      } else {
        quoteRequest.container = [];
        if (submitData.containers != null) {
          for (let i = 0; i < submitData.containers?.quantity; i++) {
            quoteRequest.container.push({
              containerType: submitData.containers?.containerType,
              weight: submitData.containers?.weight,
              weightUnit: submitData.containers?.weightUnit,
            });
          }
        }
      }

      const response = await postApi('quotes', quoteRequest);
      if (!response.ok) {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
        return;
      }
      const quotation = (await response.json()) as QuoteResponse;
      const status = quotation.quoteStatus;
      if (status.status === Status.LCL_ERROR) {
        const lclErrorMessage = (
          <p>
            Dimensions, weight or volume too high for LCL. <br /> max:
            {status.maxLengthPackagesCm}x{status.maxWidthPackagesCm}x
            {status.maxHeightPackagesCm}cm (LWH) (volume:{' '}
            {status.maxVolumePackagesCbm}cbm); {status.maxWeightPackagesKg}kg'{' '}
          </p>
        );
        errorToast({
          title: lclErrorMessage,
        });
        return;
      }
      if (status.status === Status.AIR_FREIGHT_ERROR) {
        const airFreightErrorMessage = (
          <p>
            Dimensions, weight or volume too high for air freight. <br /> max:{' '}
            {status.maxLengthPackagesCm}x{status.maxWidthPackagesCm}x
            {status.maxHeightPackagesCm}cm (LWH); {status.maxWeightPackagesKg}
            kg'
          </p>
        );
        errorToast({
          title: airFreightErrorMessage,
        });
        return;
      }
      if (status.status === Status.NOT_FOUND || response.status === 204) {
        errorToast({
          title: 'No quotations found for the requested details.',
        });
        return;
      }
      setQuotation(
        quotation.quote,
        quotation.quotingSource,
        quotation.lclMeasurement,
        quotation.ifApplicables,
        quotation.rateType,
      );
    },
    [
      currencies,
      lengthUnits,
      weightUnits,
      setFormData,
      postApi,
      errorToast,
      setQuotation,
    ],
  );

  const setTransportMode = useCallback(
    (value: number) => {
      setValue('origin', undefined);
      setValue('destination', undefined);

      if (formData) {
        setFormData({
          ...formData,
          transport: value,
          origin: undefined,
          originValue: '',
          destination: undefined,
          destinationValue: '',
          containers: undefined,
        });
      }
    },
    [formData, setFormData, setValue],
  );

  useEffect(() => {
    if (formData == null) {
      reset(defaultValues);
    }
  }, [defaultValues, formData, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Card spacing="7">
          <Heading as="h2" variant="h2">
            Transport & Currency
          </Heading>
          <SimpleGrid {...simpleGridProps}>
            <RadioFormInput
              label="Transport Type"
              accessor="transport"
              control={control}
              isRequired={true}
              isDisabled={isSubmitting}
              options={[
                { label: 'Air', value: TransportMode.Air },
                { label: 'LCL', value: TransportMode.LCL },
                { label: 'FCL', value: TransportMode.FCL },
              ]}
              setValueAs={(value) => {
                const intValue = parseInt(value as string);
                setTransportMode(intValue);
                return intValue;
              }}
            />
            <SelectFormInput
              label="Currency"
              accessor="currency"
              isRequired={true}
              isDisabled={isSubmitting}
              control={control}
              placeholder="Select currency"
              value={{
                label: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.code,
                value: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.currencyID,
              }}
              options={
                !currencies.isLoading && currencies.data
                  ? currencies.data.map((cur) => ({
                      label: cur.code,
                      value: cur.currencyID,
                    }))
                  : []
              }
              defaultValue={{
                label: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.code,
                value: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.currencyID,
              }}
            />
          </SimpleGrid>
          <Divider borderColor="grey.400" />
          <Heading as="h2" variant="h2">
            Route
          </Heading>
          <SimpleGrid {...simpleGridProps}>
            <Stack spacing={4}>
              <SearchInput
                label="Origin"
                accessor="origin"
                register={register}
                errors={errors}
                items={originItems}
                inputValue={formData?.originValue ?? ''}
                isRequired={true}
                isDisabled={isSubmitting}
                isLoading={isOriginLoading || isPortsLoading}
                placeholder="Company name, location... (powered by Google)"
                onInputValueChange={(input) => {
                  setFormData({ ...formData!, originValue: input });
                }}
                onItemChange={(selection) => {
                  onPlaceSelection(selection, true);
                }}
                itemToString={placeToString}
                displayItems={displayPlaceResults}
              />
              <PlaceDetails place={origin} />
            </Stack>
            <Stack spacing={4}>
              <SearchInput
                label="Destination"
                accessor="destination"
                register={register}
                errors={errors}
                items={destinationItems}
                inputValue={formData?.destinationValue ?? ''}
                isRequired={true}
                isDisabled={isSubmitting}
                isLoading={isDestinationLoading || isPortsLoading}
                placeholder="Company name, location... (powered by Google)"
                onInputValueChange={(input) =>
                  setFormData({ ...formData!, destinationValue: input })
                }
                onItemChange={(selection) => {
                  onPlaceSelection(selection, false);
                }}
                itemToString={placeToString}
                displayItems={displayPlaceResults}
              />
              <PlaceDetails place={destination} />
            </Stack>
          </SimpleGrid>
        </Card>
        {transport === TransportMode.FCL ? (
          <MainCard heading="Cargo Details" spacing="8">
            <ContainerInput
              control={control}
              defaultValue={containers}
              isDisabled={isSubmitting}
              value={containers}
              gridProps={simpleGridProps}
              weightUnits={weightUnits}
              register={register}
              containerTypes={containerTypes}
            />
          </MainCard>
        ) : (
          <MainCard heading="Cargo Details" spacing="8">
            <Grid
              templateColumns={{ base: 'repeat(1)', md: 'repeat(2,1fr)' }}
              gap={8}
              {...simpleGridProps}
            >
              {cargo.map(
                (packageItem: QuotationCargoFormData, index: number) => (
                  <React.Fragment key={'cargo-' + index}>
                    <CargoInput
                      accessor={`cargo[${index}]`}
                      control={control}
                      register={register}
                      errors={errors}
                      showDelete={cargo.length > 1}
                      defaultValue={packageItem}
                      lengthUnits={lengthUnits}
                      weightUnits={weightUnits}
                      onDelete={() => removePackage(index)}
                      value={packageItem}
                      isDisabled={isSubmitting}
                    />
                    {onZoom && (
                      <GridItem marginTop="-7" colSpan={colSpanValue}>
                        <Button
                          leftIcon={<DeleteIcon w="4" h="4" />}
                          backgroundColor="grey.500"
                          size="xs"
                          marginTop="5"
                          isFullWidth
                          disabled={cargo.length <= 1}
                          onClick={() => removePackage(index)}
                        >
                          Delete
                        </Button>
                      </GridItem>
                    )}
                    <GridItem marginTop="-4" colSpan={colSpanValue}>
                      <Divider borderColor="grey.400" py="2" />
                    </GridItem>
                  </React.Fragment>
                ),
              )}
              <Stack justifyContent="end">
                <HStack>
                  <InfoItem
                    title="Total Volume"
                    value={valueOrDefault(
                      volumeReduce(cargo).toFixed(3),
                      'cbm',
                      '-',
                    )}
                  />
                </HStack>
              </Stack>
              <Stack justifyContent="end">
                <HStack>
                  <InfoItem
                    title="Total Weight"
                    value={valueOrDefault(
                      weightReduce(cargo).toFixed(2),
                      selectWeightUnitString(cargo),
                      '-',
                    )}
                  />
                </HStack>
              </Stack>
              <HStack shouldWrapChildren={!onMobile}>
                <Button
                  leftIcon={<PlusIcon w="4" h="4" />}
                  colorScheme="secondary"
                  size="xs"
                  isDisabled={isSubmitting}
                  onClick={() => addPackage()}
                >
                  Add Cargo
                </Button>
              </HStack>
            </Grid>
          </MainCard>
        )}
        <Card
          direction={onMobile ? 'column' : 'row'}
          justify={!onMobile ? 'space-between' : ''}
          align={!onMobile ? 'center' : ''}
        >
          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Request Quote
          </Button>
          <Button
            type="button"
            variant="ghost"
            colorScheme="blue"
            onClick={() => {
              setFormData(undefined);
              reset(defaultValues);
            }}
            size="xs"
            isDisabled={isSubmitting}
          >
            Reset
          </Button>
        </Card>
      </Stack>
    </form>
  );
};
