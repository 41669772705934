export const ZIP_CODE_REGEX = {
  BE: /^\d{1,4}$/,
  CH: /^\d{1,4}$/,
  DE: /^\d{1,5}$/,
  ES: /^\d{1,5}$/,
  FR: /^\d{1,5}$/,
  IT: /^\d{1,5}$/,
  US: /^\d{1,5}$/,
  ZA: /^\d{1,4}$/,
  DK: /^\d{1,4}$/,
};
