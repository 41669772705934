import { Input } from '@chakra-ui/react';
import React from 'react';

interface TariffZoneInputProps {
  value: string | number | undefined;
  name: string;
  isInvalid: boolean;
  handleZoneFromChange: (val: string) => void;
}

export const TariffZoneInput = ({
  value,
  name,
  isInvalid,
  handleZoneFromChange,
}: TariffZoneInputProps) => (
  <Input
    name={name}
    variant="outline"
    bg="grey.900"
    height="30px"
    isInvalid={isInvalid}
    fontSize="sm"
    value={value}
    width="80px"
    fontWeight="500"
    borderRadius="12px"
    borderColor="grey.500"
    disabled={false}
    onChange={(e) => handleZoneFromChange(e.target.value)}
    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.target.select();
    }}
  />
);
