import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,} from '@chakra-ui/modal';
import {Button, HStack, ModalFooter, Text, Textarea, VStack,} from '@chakra-ui/react';
import React, {FunctionComponent, useState} from 'react';
import {useSuccessToast} from "../../hooks/useSuccessToast";
import {useApi} from "../../providers/ApiProvider";

interface QuoteSatisfactionModalProps {
    isOpen: boolean;
    handleModalClose: () => void;
    quoteId: string;
    wasSubmitted: boolean;
    setWasSubmitted: (value: boolean) => void;
}

export const QuoteSatisfactionModal: FunctionComponent<QuoteSatisfactionModalProps> = ({
                                                                                           isOpen,
                                                                                           handleModalClose,
                                                                                           quoteId,
                                                                                           wasSubmitted,
                                                                                           setWasSubmitted,
                                                                                       }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isQuoteCompetitive, setIsQuoteCompetitive] = useState<boolean | null>(null);
    const [comment, setComment] = useState<string>("");
    const [showWarningText, setWarningTextDisplay] = useState(false);
    const {postApi} = useApi();
    const successToast = useSuccessToast({
        title: "Feedback submitted successfully",
        description: "Thank you for your feedback",
        duration: 3000
    });

    const handleRatingChange = (value: boolean) => {
        if (isQuoteCompetitive === true && value) {
            setIsQuoteCompetitive(null);
        } else if (isQuoteCompetitive === false && !value) {
            setIsQuoteCompetitive(null);
        } else {
            setIsQuoteCompetitive(value);
        }
    }
    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        setComment(event.target.value);

    const handleSubmit =
        async () => {
            if (wasSubmitted) {
                return;
            }
            if (isQuoteCompetitive === null) {
                setWarningTextDisplay(true);
                return;
            } else {
                setWarningTextDisplay(false);
            }

            setIsLoading(true);

            try {
                const response = await postApi('quotes/rating', {
                    quoteId,
                    isQuoteCompetitive,
                    comment,
                });

                if (response.ok) {
                    handleModalClose();
                    setWasSubmitted(true);
                    successToast();
                }
            } catch (error) {
                console.error(error); // eslint-disable-line no-console
            } finally {
                setIsLoading(false);
            }
        };

    const buttonText = isLoading ? "Sending Feedback..." : "Send Feedback";

    return (
        <>
            <VStack justifyContent="center">
                <Modal isOpen={isOpen} onClose={handleModalClose}>
                    <ModalOverlay/>
                    <ModalContent minWidth={{base: '20rem', md: '37.5rem'}} bg="grey.800">
                        <ModalHeader display="flex" justifyContent="center" alignItems="center" borderBottom="4px solid"
                                     borderColor="grey.900" word-wrap="break-word"
                                     textAlign="center">
                            <HStack>
                            <span className="material-symbols-outlined"
                                  style={{fontSize: '20px'}}>reviews</span><h2 className="chakra-heading">Rate This
                                Quotation</h2></HStack></ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody paddingInline="28px" paddingBottom="32px" paddingTop="32px" paddingLeft="56px"
                                   paddingRight="56px">
                            <Text mb={8} fontSize="sm">Is this quotation market competitive? <br/> Help us improve
                                quotation results by providing feedback.</Text>
                            <Text
                                as="label"
                                justifyContent="flex-start"
                                color="gray.400"
                                fontSize="xs"
                            >
                                Rating
                            </Text>
                            <HStack mt={5} mb={6} spacing={3} justifyContent="start">
                                <Button
                                    variant={isQuoteCompetitive === true ? 'solid' : 'outline'}
                                    onClick={() => handleRatingChange(true)}
                                    colorScheme="blue"
                                    leftIcon={<span className="material-symbols-outlined">thumb_up</span>}
                                    style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        boxShadow: 'none'
                                    }}
                                >
                                    Competitive
                                </Button>
                                <Button
                                    variant={isQuoteCompetitive === false ? 'solid' : 'outline'}
                                    onClick={() => handleRatingChange(false)}
                                    mr={3}
                                    colorScheme="blue"
                                    leftIcon={<span className="material-symbols-outlined">thumb_down</span>}
                                    style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        boxShadow: 'none'
                                    }}
                                >
                                    Not Competitive
                                </Button>
                            </HStack>
                            <Text
                                as="label"
                                justifyContent="flex-start"
                                color="gray.400"
                                fontSize="xs"
                            >
                                Comment
                            </Text>
                            <Textarea paddingInline="6" borderColor="grey.700" fontSize="sm" minHeight="180px" mt="3"
                                      value={comment}
                                      _hover={{}}
                                      onChange={handleCommentChange}
                                      placeholder="Thank you for rating! If you have any specific charges that you feel are not market competitive, please share your thoughts in the comments. Your feedback helps us improve!"/>
                        </ModalBody>
                        <ModalFooter display="flex">
                            <HStack justifyContent="end">
                                <Button onClick={handleModalClose} colorScheme="grey.800">
                                    Cancel
                                </Button>
                                <Button isLoading={isLoading} onClick={handleSubmit} colorScheme="blue"
                                        loadingText="Sending Feedback...">
                                    {buttonText}
                                </Button>
                            </HStack>
                        </ModalFooter>
                        {showWarningText &&
                            <Text color="red" fontSize={"x-small"} textAlign={"center"}>*
                                Please select either ‘Competitive’ or ‘Not competitive’ before submitting your
                                feedback.</Text>}
                    </ModalContent>
                </Modal>
            </VStack>
        </>
    )
        ;
};